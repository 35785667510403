export function setResultParams (user) {
    let age = 'main'
    if (user.bdate !== undefined) {
      let year = Number(user.bdate.split('.')[2])
      if (year >= 2005) age = 'school' 
      else if (year <= 2004) age = 'student'
    }
    function getRandomInt(min, max) {
        min = Math.ceil(min); max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min; //Максимум не включается, минимум включается
    }

    let filtered =[]
    if (age == 'school') {
      filtered = data_for_result.filter(el=>el['cat'] == 'school' || el['cat'] == 'main')
    } else if (age == 'student') {
      filtered = data_for_result.filter(el=>el['cat'] == 'student' || el['cat'] == 'main')
    } else {
      filtered = data_for_result.filter(el=>el['cat'] == 'main')
    }

    const num = getRandomInt(0, filtered.length)
    let result = filtered[num]

  return result
}

let data_for_result = [
    {
      id:1,
      cat: 'school',
      text: 'Гиперскорость поедания сосисок в тесте',
    },
    {
      id:2,
      cat: 'school',
      text: 'Становиться невидимкой, когда учитель задаёт вопрос',
    },
    {
      id:3,
      cat: 'main',
      text: 'Мгновенно перемещаться во времени — до перемены',
    },
    {
      id:4,
      cat: 'student',
      text: 'Гиперскоростной бег до столовой',
    },
    {
      id:5,
      cat: 'student',
      text: 'Не спать всю сессию (и выжить)',
    },
    {
      id:6,
      cat: 'school',
      text: 'Телепортация из кровати на первый урок',
    },
    {
      id:7,
      cat: 'main',
      text: 'Дар предсказания, кого спросят сегодня',
    },
    {
      id:8,
      cat: 'student',
      text: 'Превращать воду в диплом',
    },
    {
      id:9,
      cat: 'school',
      text: 'Превращать воду в сочинение',
    },
    {
      id:10,
      cat: 'school',
      text: 'Заезжать в кабинет на коне, когда просят зайти нормально',
    },
    {
      id:11,
      cat: 'student',
      text: 'Прожить учебный год без панических атак',
    },
    {
      id:12,
      cat: 'school',
      text: 'Ни разу не зайти в школьный туалет',
    },
    {
      id:13,
      cat: 'student',
      text: 'Получить зачёт по физре силой мысли',
    },
    {
      id:14,
      cat: 'student',
      text: 'Дар самоисцеления после сессии',
    },
    {
      id:15,
      cat: 'student',
      text: 'Неуязвимость к вопросам преподавателя',
    },
    {
      id:16,
      cat: 'main',
      text: 'Путешествие во времени к лету 2023-го',
    },
    {
      id:17,
      cat: 'main',
      text: 'Видеть с галёрки, что пишет отличник за первой партой',
    },
    {
      id:18,
      cat: 'school',
      text: 'Контроль над разумом учителя',
    },
    {
      id:19,
      cat: 'school',
      text: 'Неуязвимость к тупым шуткам',
    },
    {
      id:20,
      cat: 'student',
      text: 'Левитация из кроватки на пару',
    },
    {
      id:21,
      cat: 'main',
      text: 'Желудок, который побеждает еду из столовой',
    },
    {
      id:22,
      cat: 'student',
      text: 'Высыпаться за час до пары',
    },
    {
      id:23,
      cat: 'main',
      text: 'Вставать после первого будильника',
    },
    {
      id:24,
      cat: 'main',
      text: 'Выглядеть на 100 000 р. за 5 000 р.',
    },
    {
      id:25,
      cat: 'main',
      text: 'Притягивание краша',
    },
    {
      id:26,
      cat: 'student',
      text: 'Невосприимчивость к холоду бывших',
    },
    {
      id:27,
      cat: 'main',
      text: 'Разогревать еду в столовой взглядом',
    },
    {
      id:28,
      cat: 'student',
      text: 'Превращать «3 в 1» в нормальный кофе',
    },
    {
      id:29,
      cat: 'school',
      text: 'Превращать какао в нормальный кофе',
    },
    {
      id:30,
      cat: 'school',
      text: 'Дар убеждения, что домашка сделана',
    },
    {
      id:31,
      cat: 'student',
      text: 'Иммунитет к токсикам',
    },
    {
      id:32,
      cat: 'school',
      text: 'Предчувствие контрольной',
    },
    {
      id:33,
      cat: 'student',
      text: 'Предчувствие проверочной',
    },
    {
      id:34,
      cat: 'school',
      text: 'Умение имитировать звонок с урока',
    },
    {
      id:35,
      cat: 'school',
      text: 'Стирать с доски, не трогая тряпку',
    },
    {
      id:36,
      cat: 'school',
      text: 'Быть отличником, сидя на задней парте',
    },
    {
      id:37,
      cat: 'student',
      text: 'Придумывать логичные отмазки за опоздание',
    },
    {
      id:38,
      cat: 'student',
      text: 'Способность выживать на стипендию',
    },
    {
      id:39,
      cat: 'main',
      text: 'Рассказывать что-то после слов: «И нам расскажите, вместе посмеёмся!»',
    },
    {
      id:40,
      cat: 'school',
      text: 'Начинать вести урок, когда учитель в шутку об этом попросит',
    },
    {
      id:41,
      cat: 'school',
      text: 'Кивая головой, делать вид, что всё понимаешь на уроке',
    },
    {
      id:42,
      cat: 'main',
      text: 'Умение реально забывать голову дома',
    },
]

