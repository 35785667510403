import React from 'react';
import bridge from '@vkontakte/vk-bridge';
import { useEffect } from 'react';
import {useDispatch} from "react-redux";
import {setActivePanel, setUser} from "../store/mainReducer"


const Init = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        async function getUserData() {
          const user = await bridge.send('VKWebAppGetUserInfo');
          if (user) {return user;}
        }
        getUserData().then(data =>dispatch(setUser(data)))

        const loading = setTimeout(() => {
            dispatch(setActivePanel('start'))
        }, 2500);
        return () => clearTimeout(loading);
      }, []);

    return (
    <div className="container start-page">
        <img className="start-page__image" width="294" height="318" src="assets/images/main.svg" alt="" />
        <p className="start-page__caption">
            <span>ГЕНЕРАТОР</span>
            суперспособностей
        </p>
        <div className="loading-block container__loading-block">
            <div className="loading-block__wrapper">
                <div className="loading-block__bar"></div>
            </div>
        </div>
    </div>
    );
}

export default Init;

