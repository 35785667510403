import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import {setActivePanel} from "../store/mainReducer"


const LoadingPage = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        let count = 0;
        const counting = setInterval(function(){
            if(count < 101) {
                document.querySelector('.loading-page__number-value').innerHTML = count + '%';
                count++
            } else {
                clearInterval(counting)
                dispatch(setActivePanel('resultpage'))
            }
        }, 20);
      }, []);

    return (
        <div className="container loading-page">
            <div className="loading-page__speed-pic">
                <img src="assets/images/speed-shape.svg" width="396" height="220" alt="" />
            </div>
            <p className="loading-page__caption">Генерируем твою<br/>суперспособность</p>
            <div className="loading-page__number">
                <div className="loading-page__left-bolt">
                    <img src="assets/images/bolt-raw.svg" width="132" height="134" alt="" />
                </div>
                <p className="loading-page__number-value">0%</p>
                <div className="loading-page__right-bolt">
                <img src="assets/images/bolt-raw.svg" width="132" height="134" alt="" />
                </div>
            </div>
        </div>
    );
}

export default LoadingPage;

