import {createSlice} from "@reduxjs/toolkit";

const mainSlice = createSlice({
    name: 'main',
    initialState: {
        activePanel: 'init',
        user: null,
        age: 42,
    },
    reducers: {
        setActivePanel(state, action) {
            state.activePanel = action.payload
        },
        setUser(state,action){
            state.user = action.payload
        },
        setAge(state, action) {
            state.age = action.payload
        },
    }
})

export default mainSlice.reducer
export const {setActivePanel, setUser, updateAge} = mainSlice.actions
