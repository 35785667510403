import React from 'react';
import {useDispatch} from "react-redux";
import {setActivePanel} from "../store/mainReducer"


const Start = () => {
    const dispatch = useDispatch()

    return (
        <div className="container cat-page">
            <div className="cat-block cat-page__cat-block" style={{backgroundImage: 'assets/images/static/cat-pic.png'}}>
                <p className="cat-block__text">
                    В этом приложении раздают суперсилы на следующий учебный год. <br/><b>Нажми на кнопку, чтобы узнать, какая будет у тебя!</b>
                </p>
                
            </div>
            <button onClick={()=>dispatch(setActivePanel('loadingpage'))} className="cat-page__button">
                <span>Узнать свою суперсилу</span>
            </button>
        </div>
    );
}

export default Start;
