import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setActivePanel} from "../store/mainReducer"
import bridge from '@vkontakte/vk-bridge';
import {setResultParams} from '../utils'

const ResultPage = () => {
    const dispatch = useDispatch()
    const user = useSelector(state=>state.main.user)
    const [result, setResult] = useState({text:'', id: ''})

    useEffect(()=>{
        let res = setResultParams(user)
        setResult(res)
    },[])

     async function story() {
      let url = `https://vk-generator.gamesmm.ru/assets/images/story/Share${result.id}.png`
      const story = await bridge.send("VKWebAppShowStoryBox", {
          "background_type" : "image",
          "url": url,
          "attachment": {
              "text": "open",
              "type": "url",
              "url": "https://vk.com/app51408486"
          }})
      if (story) {
          if (story.result === true) {
              console.log('shared')
            }
        }
    }
 

    return (
    <div className="container result-page">
        <div className="result-frame">
            <p className="result-frame__header">
                РЕЗУЛЬТАТ
            </p>
            <div className="result-block">
                <div className="result-character">
                    <p className="result-cloud">Моя<br/>суперсила...</p>
                    <img src={`/assets/images/stickers/${result.id}.png`} alt='' width='154' heigh='154'/>
                </div>
                <div className="result-text">
                    <p>{result.text}</p>
                </div>
            </div>
        </div>
        <div className="result-page__footer">
            <button onClick={()=>dispatch(setActivePanel('loadingpage'))} className='result-page__button result-page__repeat'>
                попробовать<br/>снова
            </button>
            <button onClick={story} className='result-page__button result-page__repeat'>
                поделиться<br/>в истории
            </button>
        </div>
    </div>
    );
}

export default ResultPage;
