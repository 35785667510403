import React from 'react';
import {useSelector} from "react-redux";
import Init from "./panels/Init";
import Start from './panels/Start';
import ResultPage from './panels/ResultPage';
import LoadingPage from './panels/LoadingPage';


const App = () => {
  const activePanel = useSelector(state => state.main.activePanel)

  let content = []
  if (activePanel === 'init') {
    content.push(<Init key='productpage'/>)
  } else if (activePanel === 'start') {
    content.push(<Start key='start'/>)
  } else if (activePanel === 'resultpage') {
    content.push(<ResultPage key='resultpage'/>)
  } else if (activePanel === 'loadingpage') {
    content.push(<LoadingPage key='loadingpage'/>)
  }

  return (
      <div className='app'>
        <>
          {content}
        </>
      </div>
  );
}
export default App;
